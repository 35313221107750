<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-form ref="form">
          <v-row>
            <v-col>
              Verticals
            </v-col>
          </v-row>

          <v-row>
            <v-col md="2">
              <v-autocomplete v-model="selectedIsActive" :items="datatables_booleanItems" item-text="name" item-value="id" label="Active" 
                clearable prepend-icon="toggle_on">
              </v-autocomplete>
            </v-col>

            <v-col md="2">
              <v-autocomplete v-model="selectedIsSpeedboat" :items="datatables_booleanItems" item-text="name" item-value="id" label="Speedboat" 
                clearable prepend-icon="speed">
              </v-autocomplete>
            </v-col>

            <v-col md="2">
              <v-text-field v-model="nameSearch" label="Name search" clearable prepend-icon="text_snippet">
              </v-text-field>
            </v-col>

            <v-col md="2">
              <v-btn class="mt-2" color="primary" @click="show">Show</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active" small>done</v-icon>
      </template>

      <template v-slot:[`item.is_speedboat`]="{ item }">
        <v-icon v-if="item.is_speedboat" small>done</v-icon>
      </template>

      <template v-slot:[`item.entry_at`]="{ item }">
        {{ datatables_formatTimestamp(item.entry_at) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'Vertical', params: { verticalID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'VerticalsTable',

  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'ShortName', value: 'short_name' },
        { text: 'CMS Name', value: 'top5_project_name' },
        { text: 'Active', value: 'is_active' },
        { text: 'Speedboat', value: 'is_speedboat' },
        { text: 'Entered', value: 'entry_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      selectedIsActive: null,
      selectedIsSpeedboat: null,
      nameSearch: '',
      lsOptions: 'verticals_options',
      lsFilters: 'verticals_filters',
      verticalsEndpoint: '/a/core/verticals',
    }
  },

  mixins: [dataTablesMixin],

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      this.showAPIError = false
      this.loading = true
      var myURL = this.verticalsEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // exclude None
      myURL += '&id=-0'

      // filter is_active
      if (this.selectedIsActive) {
        myURL += '&is_active=' + this.selectedIsActive
      }

      // filter is_speedboat
      if (this.selectedIsSpeedboat) {
        myURL += '&is_speedboat=' + this.selectedIsSpeedboat
      }

      // filter name search
      if (this.nameSearch) {
        myURL += '&name=~' + this.nameSearch + '~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
      let filterObj = {
        'selectedIsActive': this.selectedIsActive,
        'selectedIsSpeedboat': this.selectedIsSpeedboat,
        'name_search': this.nameSearch
      }
      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedIsActive']) { this.selectedIsActive = filters['selectedIsActive'] }
      if (filters['selectedIsSpeedboat']) { this.selectedIsSpeedboat = filters['selectedIsSpeedboat'] }
      if (filters['nameSearch']) { this.nameSearch = filters['name_search'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
