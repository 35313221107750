var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',[_vm._v(" Verticals ")])],1),_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.datatables_booleanItems,"item-text":"name","item-value":"id","label":"Active","clearable":"","prepend-icon":"toggle_on"},model:{value:(_vm.selectedIsActive),callback:function ($$v) {_vm.selectedIsActive=$$v},expression:"selectedIsActive"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.datatables_booleanItems,"item-text":"name","item-value":"id","label":"Speedboat","clearable":"","prepend-icon":"speed"},model:{value:(_vm.selectedIsSpeedboat),callback:function ($$v) {_vm.selectedIsSpeedboat=$$v},expression:"selectedIsSpeedboat"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-text-field',{attrs:{"label":"Name search","clearable":"","prepend-icon":"text_snippet"},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":_vm.show}},[_vm._v("Show")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}},{key:"item.is_active",fn:function(ref){
    var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"small":""}},[_vm._v("done")]):_vm._e()]}},{key:"item.is_speedboat",fn:function(ref){
    var item = ref.item;
return [(item.is_speedboat)?_c('v-icon',{attrs:{"small":""}},[_vm._v("done")]):_vm._e()]}},{key:"item.entry_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datatables_formatTimestamp(item.entry_at))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{ name: 'Vertical', params: { verticalID: item.id }}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }